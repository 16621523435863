import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import isEmpty from "ramda/src/isEmpty"
import equals from "ramda/src/equals"
import take from "ramda/src/take"

import useSelectCity from "../hooks/useSelectCity"

import { getCityName, getStateName } from "../utils/places"

import Label from "./Label"
import Input from "./Input"

const takeFour = take(4)

const WrapperInput = styled.div`
  ${tw`mb-4 relative`}
`

const SearchResult = styled.div`
  ${tw`absolute w-full shadow-lg bg-white border-secondary border rounded-md mt-3 px-3 py-1`}
`

const RowResult = styled.span`
  ${tw`
    w-full py-2 text-base border-b border-paragraph border-opacity-30 hover:bg-blue
    hover:bg-opacity-40 block outline-none
  `}

  &:not(:focus) {
    ${({ hovered }) => hovered && tw`bg-blue bg-opacity-40`}
  }

  &:focus {
    ${tw`bg-blue bg-opacity-40`}
  }

  &:last-of-type {
    ${tw`border-none`}
  }
`

const Estado = styled.span`
  ${tw`uppercase`}
`

function ResultCity(props) {
  const { children: city, hovered, ...rest } = props

  return (
    <RowResult hovered={hovered ? 1 : undefined} {...rest}>
      {getCityName(city)} - <Estado>{getStateName(city)}</Estado>
    </RowResult>
  )
}

function CityField({
  name,
  label = "Cidade",
  type = "text",
  value = "",
  setField,
  ...rest
}) {
  // const [term, setTerm] = React.useState(value)
  const { cities, cursor } = useSelectCity(value)

  return (
    <WrapperInput>
      <Label htmlFor={name}>{label}</Label>
      <Input
        placeholder="Cidade - UF"
        value={value}
        name={name}
        id={name}
        type={type}
        autoComplete="off"
        onKeyDown={event => {
          if ("Escape" === event.key) {
            setField("")
          } else if ("Enter" === event.key) {
            event.preventDefault()
            if (!isEmpty(cities)) {
              const city = cities[cursor].node

              setField(`${getCityName(city)} - ${getStateName(city)}`)
            }
          } else if ("ArrowUp" === event.key) {
            event.preventDefault()
          } else if ("ArrowDown" === event.key) {
            event.preventDefault()
          }
        }}
        {...rest}
      />
      {value && !isEmpty(cities) && (
        <SearchResult>
          {takeFour(cities).map(({ node: city }, index) => (
            <ResultCity
              key={`${getCityName(city)}-${getStateName(city)}`}
              onClick={() =>
                setField(`${getCityName(city)} - ${getStateName(city)}`)
              }
              hovered={equals(index, cursor)}
            >
              {city}
            </ResultCity>
          ))}
        </SearchResult>
      )}
    </WrapperInput>
  )
}

export default CityField
