import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"

import formReducer from "../utils/formReducer"
import encode from "../utils/encode"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import WrapperHeader from "../components/header/WrapperHeader"
import LogoLocalDeProva from "../components/header/LogoLocalDeProva"
import Nav from "../components/header/Nav"
import H1 from "../components/H1"
import Divider from "../components/Divider"
import InputField from "../components/InputField"
import CityField from "../components/CityField"
import ButtonPrimary from "../components/ButtonPrimary"
import Alert from "../components/Alert"

const CustomButtonPrimary = styled(ButtonPrimary)`
  ${tw`w-full md:w-auto px-16`}
`

const Form = styled.form`
  ${tw`w-full lg:w-3/5`}
`

const WrapperContent = styled.div`
  ${tw`lg:mt-20`}
`

const initialState = {
  isSending: false,
  isError: false,
  message: "",
  form: { cidade: "", nomeDoLocal: "", rua: "", bairro: "" },
}
export default function CadastrarLocal(props) {
  const [state, dispatch] = React.useReducer(formReducer, {
    ...initialState,
    form: {
      ...initialState.form,
      cidade: props.location?.state?.city || "",
    },
  })

  const setField = React.useCallback(
    fieldObject => dispatch({ type: "setField", payload: fieldObject }),
    []
  )

  const handleChange = React.useCallback(
    e => {
      setField({ [e.target.name]: e.target.value })
    },
    [setField]
  )

  const closeAlert = React.useCallback(() => {
    dispatch({ type: "clearMessage" })
  }, [])

  const handleSubmit = e => {
    e.preventDefault()

    const form = e.target
    dispatch({ type: "send" })
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state.form,
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw response
        }

        dispatch({
          type: "sendSuccess",
          payload: "Sua sugestão de local foi enviada e será analisada.",
        })
        dispatch({ type: "reset", payload: initialState.form })
      })
      .catch(() =>
        dispatch({
          type: "sendFailure",
          payload: "Não foi possível enviar sua sugestão. Tente mais tarde.",
        })
      )
  }

  return (
    <Layout>
      <SEO title="Cadastrar local" />

      <WrapperHeader>
        <LogoLocalDeProva />
        <Nav />
      </WrapperHeader>

      <WrapperContent>
        <H1>Cadastrar local</H1>
        <Divider />

        {state.message && (
          <Alert type={state.isError ? "error" : undefined} close={closeAlert}>
            {state.message}
          </Alert>
        )}

        <Form
          name="cadastrar-local"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="contact" />
          <p hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </p>
          <CityField
            name="cidade"
            value={state.form.cidade}
            onChange={handleChange}
            required
            disabled={state.isSending}
            setField={text => {
              const fieldObject = {
                cidade: text,
              }
              setField(fieldObject)
            }}
          />
          <InputField
            name="nomeDoLocal"
            label="Nome do local"
            value={state.form.nomeDoLocal}
            onChange={handleChange}
            required
            disabled={state.isSending}
          />
          <InputField
            name="rua"
            label="Rua"
            value={state.form.rua}
            onChange={handleChange}
            required
            disabled={state.isSending}
          />
          <InputField
            name="bairro"
            label="Bairro"
            value={state.form.bairro}
            onChange={handleChange}
            required
            disabled={state.isSending}
          />
          <CustomButtonPrimary type="submit" disabled={state.isSending}>
            {state.isSending ? "Enviando..." : "Enviar"}
          </CustomButtonPrimary>
        </Form>
      </WrapperContent>
    </Layout>
  )
}
